import React from "react"
import Header from '../../components/header'
import Footer from '../../components/footer_de'
import Container from '../../components/container'
import SEO from '../../components/seo'
import icon from "../../images/info/UTC_App_iOS_Icon.svg"

export default function Home() {
  return (
    <div>
        <SEO title="Info" description="Info über Universal Time Complication App" />
        <Header captionText="App"/>
        <Container>
          <p>Universal Time Complication ist eine Apple Watch only App, die eine Complication zum Anzeigen von Zeit zur Verfügung stellt.</p>
          <p>Die in der Complication angezeigte Zeit kann einfach angepasst werden, indem eine Differenz zur derzeitigen Ortszeit oder der koordinierten Weltzeit (abgekürzt UTC) eingestellt wird. Es kann eine beliebige Abweichung zwischen -23 Stunden und -59 Minuten bis +23 Stunden und +59 Minuten eingestellt werden.</p>
          <p>
            <div>In der Complication sind folgende Kombinationen zur Anzeige möglich:</div>
            <div><span>&#183;</span>derzeitige Ortszeit</div>
            <div><span>&#183;</span>koordinierte Weltzeit (UTC)</div>
            <div><span>&#183;</span>angepasste Zeit</div>
            <div><span>&#183;</span>derzeitige Ortszeit +  koordinierte Weltzeit (UTC) (nicht auf allen Zifferblätter verfügbar)</div>
            <div><span>&#183;</span>derzeitige Ortszeit + angepasste Zeit (nicht auf allen Zifferblätter verfügbar)</div>
            <div><span>&#183;</span>angepasste Zeit + koordinierte Weltzeit (UTC) (nicht auf allen Zifferblätter verfügbar)</div>
          </p>
          <p>Das Format der angezeigten Zeit ist immer "HH:MM". Eine Umstellung auf am/pm ist nicht möglich.</p>
          <p>In der Complication wird immer die derzeitige Abweichung zur Ortszeit in Stunden angegeben. Zum Beispiel "HH:MM, +3", wenn eine Abweichung von +3 Stunden zur Ortszeit eingestellt. Wenn in der App UTC als Basis-Zeit eingestellt wurde, dann kann die Zeitabweichung in der Complication sich von der einstellte Abweichung in der App unterscheiden, da die Complication immer die Abweichung von der Ortszeit angibt. Zum Beispiel: Ortszeit = UTC+2 (MESZ), Basis-Zeit in der App ist UTC, eingestellte Abweichung in der App sind -3 Stunden - dann wird die Complication wie folgt angezeigt: "HH:MM, -1".</p>
      {/* <img src={icon} alt="Icon"/> */}
      {
      //TODO Footer ueberdeckt Text, das muss behoben werden, kurzzeitiger Fix durch zustätzliche Elemente
      }
      </Container>
        <Footer />
      </div>
      )
}
